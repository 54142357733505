import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter  } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store, {persistor} from './store';
import { PersistGate } from 'redux-persist/integration/react';
import Layouts from './layouts'
import { THEME_CONFIG } from './configs/AppConfig';
import './lang'

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <ThemeSwitcherProvider 
              themeMap={themes} 
              defaultTheme={THEME_CONFIG.currentTheme} 
              insertionPoint="styles-insertion-point"
            >
              <Layouts />
            </ThemeSwitcherProvider>
          </BrowserRouter>
        </PersistGate>  
      </Provider>
    </div>
  );
}

export default App;
