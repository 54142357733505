const FirebaseConfig = {
  apiKey: 'AIzaSyB2oNt5RS0-Bl6vrlJcGY-9D_GSv_KY24M',
  authDomain: 'kids-first-381902.firebaseapp.com',
  // databaseURL: 'https://emilus.firebaseio.com',
  projectId: 'kids-first-381902',
  storageBucket: 'kids-first-381902.appspot.com',
  messagingSenderId: '690010889353',
  appId: '1:690010889353:web:f8f5fce5a98aeaa78b3a58',
  // measurementId: 'G-8KE7HJB252'
};

export default FirebaseConfig;
