import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from './AppConfig'

export const publicRoutes = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() => import('../views/auth-views/authentication/login')),
  },
  {
    key: 'login-1',
    path: `${AUTH_PREFIX_PATH}/login-1`,
    component: React.lazy(() => import('../views/auth-views/authentication/login-1')),
  },
  {
    key: 'login-2',
    path: `${AUTH_PREFIX_PATH}/login-2`,
    component: React.lazy(() => import('../views/auth-views/authentication/login-2')),
  },
  {
    key: 'register-select-language',
    path: `${AUTH_PREFIX_PATH}/register/select-language`,
    component: React.lazy(() => import('../views/auth-views/authentication/register/select-language')),
  },
  {
    key: 'register-select-profile',
    path: `${AUTH_PREFIX_PATH}/register/select-profile`,
    component: React.lazy(() => import('../views/auth-views/authentication/register/select-profile')),
  },
  {
    key: 'register-signup',
    path: `${AUTH_PREFIX_PATH}/register/signup`,
    component: React.lazy(() => import('../views/auth-views/authentication/register/signup')),
  },
  {
    key: 'register-pricing',
    path: `${AUTH_PREFIX_PATH}/register/pricing`,
    component: React.lazy(() => import('../views/auth-views/authentication/register/pricing')),
  },
  {
    key: 'register-forgot-password',
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() => import('../views/auth-views/authentication/login/forgot-password')),
  },
  {
    key: 'register-help',
    path: `${AUTH_PREFIX_PATH}/register/help`,
    component: React.lazy(() => import('../views/auth-views/authentication/register/help')),
  },
  {
    key: 'register-terms',
    path: `${AUTH_PREFIX_PATH}/register/terms`,
    component: React.lazy(() => import('../views/auth-views/authentication/register/terms')),
  },
  {
    key: 'register-privacy',
    path: `${AUTH_PREFIX_PATH}/register/privacy`,
    component: React.lazy(() => import('../views/auth-views/authentication/register/privacy')),
  },
  {
    key: 'register-1',
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(() => import('../views/auth-views/authentication/register-1')),
  },
  {
    key: 'register-2',
    path: `${AUTH_PREFIX_PATH}/register-2`,
    component: React.lazy(() => import('../views/auth-views/authentication/register-2')),
  },
  {
    key: 'forgot-password',
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() => import('../views/auth-views/authentication/forgot-password')),
  },
  {
    key: 'error-page-1',
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import('../views/auth-views/errors/error-page-1')),
  },
  {
    key: 'error-page-2',
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import('../views/auth-views/errors/error-page-2')),
  },
]

export const protectedRoutes = [
  {
    key: 'dashboard.parent',
    path: `${APP_PREFIX_PATH}/dashboards/parent`,
    component: React.lazy(() => import('../views/app-views/dashboards/parent')),
  },
  {
    key: 'dashboard.conflict',
    path: `${APP_PREFIX_PATH}/dashboards/conflict`,
    component: React.lazy(() => import('../views/app-views/dashboards/widget/widget-detail/conflict')),
  },
  {
    key: 'dashboard.homework',
    path: `${APP_PREFIX_PATH}/dashboards/homework`,
    component: React.lazy(() => import('../views/app-views/dashboards/widget/widget-detail/homework')),
  },
  {
    key: 'parenting-plans',
    path: `${APP_PREFIX_PATH}/parenting-plans`,
    component: React.lazy(() => import('../views/app-views/parenting-plans')),
  },
  {
    key: 'first-steps',
    path: `${APP_PREFIX_PATH}/parenting-plans/first-steps`,
    component: React.lazy(() => import('../views/app-views/parenting-plans/first-steps')),
  },
  {
    key: 'first-steps.dashboard',
    path: `${APP_PREFIX_PATH}/parenting-plans/first-steps/dashboard`,
    component: React.lazy(() => import('../views/app-views/parenting-plans/first-steps/dashboard')),
  },
  {
    key: 'first-steps.caseStatus',
    path: `${APP_PREFIX_PATH}/parenting-plans/first-steps/case-status`,
    component: React.lazy(() => import('../views/app-views/parenting-plans/first-steps/case-status')),
  },
  {
    key: 'first-steps.planEditors',
    path: `${APP_PREFIX_PATH}/parenting-plans/first-steps/plan-editors`,
    component: React.lazy(() => import('../views/app-views/parenting-plans/first-steps/plan-editors')),
  },
  {
    key: 'first-steps.relationshipInfo',
    path: `${APP_PREFIX_PATH}/parenting-plans/first-steps/relationship-info`,
    component: React.lazy(() => import('../views/app-views/parenting-plans/first-steps/relationship-info')),
  },
  {
    key: 'first-steps.planSettings',
    path: `${APP_PREFIX_PATH}/parenting-plans/first-steps/plan-settings`,
    component: React.lazy(() => import('../views/app-views/parenting-plans/first-steps/plan-settings')),
  },
  {
    key: 'care-community',
    path: `${APP_PREFIX_PATH}/care-community`,
    component: React.lazy(() => import('../views/app-views/care-community')),
  },
  {
    key: 'care-community.index',
    path: `${APP_PREFIX_PATH}/care-community/index`,
    component: React.lazy(() => import('../views/app-views/care-community')),
  },
  {
    key: 'care-community.myself',
    path: `${APP_PREFIX_PATH}/care-community/myself`,
    component: React.lazy(() => import('../views/app-views/care-community/myself')),
  },
  {
    key: 'care-community.editProfile',
    path: `${APP_PREFIX_PATH}/care-community/edit-profile/:profileId/:isOwner`,
    component: React.lazy(() => import('../views/app-views/care-community/profile')),
  },
  {
    key: 'care-community.everyone',
    path: `${APP_PREFIX_PATH}/care-community/everyone`,
    component: React.lazy(() => import('../views/app-views/care-community/everyone')),
  },
  {
    key: 'care-community.parent',
    path: `${APP_PREFIX_PATH}/care-community/parent`,
    component: React.lazy(() => import('../views/app-views/care-community/parent')),
  },
  {
    key: 'care-community.children',
    path: `${APP_PREFIX_PATH}/care-community/children`,
    component: React.lazy(() => import('../views/app-views/care-community/children')),
  },
  {
    key: 'care-community.extendedFamily',
    path: `${APP_PREFIX_PATH}/care-community/extended_family`,
    component: React.lazy(() => import('../views/app-views/care-community/family')),
  },
  {
    key: 'care-community.family_law_attorney',
    path: `${APP_PREFIX_PATH}/care-community/family_law_attorney`,
    component: React.lazy(() => import('../views/app-views/care-community/legal')),
  },
  {
    key: 'care-community.invite',
    path: `${APP_PREFIX_PATH}/care-community/invite`,
    component: React.lazy(() => import('../views/app-views/care-community/invite')),
  },
  {
    key: 'care-community.backgroundInfo',
    path: `${APP_PREFIX_PATH}/care-community/background-info/:profileId/:isOwner`,
    component: React.lazy(() => import('../views/app-views/care-community/background-info')),
  },
  {
    key: 'compare-answers',
    path: `${APP_PREFIX_PATH}/compare-answers`,
    component: React.lazy(() => import('../views/app-views/compare-answers')),
  },
  {
    key: 'compare-answers.index',
    path: `${APP_PREFIX_PATH}/compare-answers/index`,
    component: React.lazy(() => import('../views/app-views/compare-answers')),
  },
  {
    key: 'compare-answers.parenting',
    path: `${APP_PREFIX_PATH}/compare-answers/parenting`,
    component: React.lazy(() => import('../views/app-views/compare-answers/parenting')),
  },
  {
    key: 'dashboard.default',
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import('../views/app-views/dashboards/default')),
  },
  {
    key: 'dashboard.analytic',
    path: `${APP_PREFIX_PATH}/dashboards/analytic`,
    component: React.lazy(() => import('../views/app-views/dashboards/analytic')),
  },
  {
    key: 'dashboard.sales',
    path: `${APP_PREFIX_PATH}/dashboards/sales`,
    component: React.lazy(() => import('../views/app-views/dashboards/sales')),
  },
  {
    key: 'login-1',
    path: `${APP_PREFIX_PATH}/login-1`,
    component: React.lazy(() => import('../views/auth-views/authentication/login-1')),
    meta: {
      blankLayout: true
    }
  },
  {
    key: 'login-2',
    path: `${APP_PREFIX_PATH}/login-2`,
    component: React.lazy(() => import('../views/auth-views/authentication/login-2')),
    meta: {
      blankLayout: true
    }
  },
  {
    key: 'register-1',
    path: `${APP_PREFIX_PATH}/register-1`,
    component: React.lazy(() => import('../views/auth-views/authentication/register-1')),
    meta: {
      blankLayout: true
    }
  },
  {
    key: 'register-2',
    path: `${APP_PREFIX_PATH}/register-2`,
    component: React.lazy(() => import('../views/auth-views/authentication/register-2')),
    meta: {
      blankLayout: true
    }
  },
  {
    key: 'forgot-password',
    path: `${APP_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() => import('../views/auth-views/authentication/forgot-password')),
    meta: {
      blankLayout: true
    }
  },
  {
    key: 'error-page-1',
    path: `${APP_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import('../views/auth-views/errors/error-page-1')),
  },
  {
    key: 'error-page-2',
    path: `${APP_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import('../views/auth-views/errors/error-page-2')),
    meta: {
      blankLayout: true
    }
  },
  {
    key: 'calendar',
    path: `${APP_PREFIX_PATH}/calendar`,
    component: React.lazy(() => import('../views/app-views/calendar')),
  },
  {
    key: 'calendar.index',
    path: `${APP_PREFIX_PATH}/calendar/index`,
    component: React.lazy(() => import('../views/app-views/calendar')),
  },
  {
    key: 'calendar.calendar_view',
    path: `${APP_PREFIX_PATH}/calendar/calendar_view`,
    component: React.lazy(() => import('../views/app-views/calendar/CalendarView')),
  },
  {
    key: 'calendar.custody_schedule',
    path: `${APP_PREFIX_PATH}/calendar/schedule-list`,
    component: React.lazy(() => import('../views/app-views/calendar/ScheduleList')),
  },
  {
    key: 'dashboard.dropoff',
    path: `${APP_PREFIX_PATH}/dashboards/drop-off`,
    component: React.lazy(() => import('../views/app-views/dashboards/widget/widget-detail/drop-off-pick-up')),
  },
  {
    key: 'dashboard.time_trade',
    path: `${APP_PREFIX_PATH}/dashboards/time-trade`,
    component: React.lazy(() => import('../views/app-views/dashboards/widget/widget-detail/time-trade')),
  },
  {
    key: 'dashboard.event_request',
    path: `${APP_PREFIX_PATH}/dashboards/event-req`,
    component: React.lazy(() => import('../views/app-views/dashboards/widget/widget-detail/event-request')),
  },
  {
    key: 'dashboard.forget_me_not_list',
    path: `${APP_PREFIX_PATH}/dashboards/forget-me-not-list`,
    component: React.lazy(() => import('../views/app-views/dashboards/widget/widget-detail/forget-me-not-list')),
  },
  {
    key: 'dashboard.child_location',
    path: `${APP_PREFIX_PATH}/dashboards/child-location`,
    component: React.lazy(() => import('../views/app-views/dashboards/widget/widget-detail/child-location')),
  },
]
